import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const ServicesSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulWebsiteServicesPage {
        nodes {
          firstServiceImage {
            file {
              url
              fileName
            }
          }
          firstServiceTitle
          firstServiceList {
            childContentfulRichText {
              html
            }
          }
          secondServiceImage {
            file {
              url
              fileName
            }
          }
          secondServiceTitle
          secondServiceList {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
  `)

  const servicesData = data.allContentfulWebsiteServicesPage.nodes[0]

  return (
    <section id="website-services-section">
      <Container>
        <Row>
          <Col md={6} className="website-service-wrapper first-col">
            <Fade cascade duration={2500}>
              <div className="website-service-head">
                <img
                  className="img-fluid"
                  src={servicesData.firstServiceImage.file.url}
                  alt={servicesData.firstServiceImage.file.fileName}
                />
                <h3>01 – Services</h3>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: servicesData.firstServiceTitle,
                  }}
                />
              </div>

              <div
                className="website-service-list"
                dangerouslySetInnerHTML={{
                  __html:
                    servicesData.firstServiceList.childContentfulRichText.html,
                }}
              />
            </Fade>
          </Col>

          <Col
            lg={5}
            md={6}
            className="website-service-wrapper sec-col offset-lg-1"
          >
            <Fade cascade duration={2500}>
              <div className="website-service-head">
                <img
                  className="img-fluid"
                  src={servicesData.secondServiceImage.file.url}
                  alt={servicesData.secondServiceImage.file.fileName}
                />
                <h3>02 – Services</h3>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: servicesData.secondServiceTitle,
                  }}
                />
              </div>

              <div
                className="website-service-list"
                dangerouslySetInnerHTML={{
                  __html:
                    servicesData.secondServiceList.childContentfulRichText.html,
                }}
              />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ServicesSection
