import React from "react"
import HeroSection from "./hero-section"
import ServicesSection from "./services"

const WebsiteServices = () => {
  return (
    <>
      <HeroSection />
      <ServicesSection />
    </>
  )
}

export default WebsiteServices
