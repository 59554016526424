import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WebsiteServices from "../components/website-services"

const WebsiteServicesPage = ({ data }) => {
  const metaData = data.allContentfulWebsiteServicesPage.nodes[0]

  return (
    <Layout>
      <SEO title="SEO Content Sites in Wordpress & snapback domains" description={metaData.metaDescription} />
      <WebsiteServices />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulWebsiteServicesPage {
      nodes {
        metaDescription
      }
    }
  }
`

export default WebsiteServicesPage
